* {
    box-sizing: border-box;
}
body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.rec.rec-pagination {
    margin-top: 5px;
}

.rec.rec-dot {
    background-color: rgba(65, 91, 67, 0.5);
    box-shadow: none;
    width: 9px;
    height: 9px;
    margin: 3px;
}
.rec.rec-dot_active {
    background-color: #415b43;
    box-shadow: none;
}
.rec.rec-dot:hover,
.rec.rec-dot_active:hover {
    background-color: #415b43;
    box-shadow: none;
}
.PhoneInputCountrySelectArrow {
    color: black;
}

.PhoneInputInput {
    ::placeholder {
        color: var(--Solid-Colours-Brand-Gray-Gray-500, #cacdd5);
        font-weight: 400;
        font-size: 15.85px;
    }
    border-radius: 6px;
    border: 1px solid #00000050;
    padding: 0.9em;
    &:focus {
        outline: none;
    }
}
